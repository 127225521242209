import styled from 'styled-components';
import { Tabs } from "react-bootstrap";

const PersonDetailCard = styled.div`
max-width: 1246px;
width: 100%;
margin: auto;
padding:25px;
.rewards{
  width: 100%;
  position:relative;
  height:0px;
  >img{
    width: 42px;
    height: 57px;
    position:relative;
    z-index: 10;
  }
}
.card-wrapper{
  max-width: 1246px;
  height: 190px;
  display:flex;
  margin-bottom: 10px;
  background: #FFFFFF;
  width: 100%;
  padding: 15px;
  border-radius: 6px;
}

    .profile-img-section{
        width:160px;
        height:160px;
        >img{
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
    }
    #person-img{
        height:160px;
        width:160px;
    }

    .profile-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .username-location-wrapper{
          height:40%;
        }
        .points-section-wrapper{
          
        }

.user-name{
  font-family: 'Rubik-Medium';
  font-size: 18px;
  color: #0D4270;
  margin-left: 25px;
  margin-bottom: 10px;
  display: flex;
  width:100%;
  .wellness-champion{
    width: 130px;
    height:16px;
    margin: auto;
    margin-left:10px;
    background-image : url("/images/PeoplePage/wellnessChampion.png");
    background-size: 100% 100%;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;

  }
}
  }

        .icons-section{
            display: flex;
            padding-left:5px;
            .place-icon{
                display: flex;
            }
            .flag{
              width:10.21px;
              height:17.31px;
              margin-left:20px
            }
            .text{
              height: 20px;
              margin-left:5px;
              text-transform:capitalize;
            }
            .location{
                // width:65%;
                margin-left: 15px;
                color:#0D4270;
            }
            .flag2{
              width:14.81px;
              height:20px;
              margin-right:5.19px;
              margin-left:5px;
            }
            .userRoleIcon{
              width:auto;
              height:20px;
              margin-right:5.19px;
              margin-left:5px;
            }
        }
         
        .points-section{
          display: flex;
          .my-points{
            border: 1px dashed rgba(156, 156, 156, 0.3);
            border-radius: 3px;
            margin-left:25px;
            width: 120px;
            height: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10px;
            .my-points-txt{
              opacity :0.5;
              font-size:14px;
            }
            .my-points-val{
              color:#FD7175;
              font-size:24px;
              font-weight: 700;
            }
          }
            
            .my-buddies{
              border: 1px dashed rgba(156, 156, 156, 0.3);
              border-radius: 3px;
              margin-left:15px;
              width: 120px;
              height: 70px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 10px;
            }
            .my-buddies-txt{
              opacity :0.5;
              font-size:14px;
            }
            .my-buddies-val{
                color:#69C2FF;
                font-size:24px;
                font-weight: 700;
            }
        }
    }
    
    .buttons-wrapper{
      display: flex;
      margin: auto;
      margin-right: 0px;
      margin-bottom: 0px;
      justify-content: flex-end;
      align-items: flex-end;
    }
        .user-role-btn{
            width: 150px;
            height: 40px;
            background-color:#69C2FF;
            color:white;
            border:none;
            border-radius: 3px;
            font-size:110%;
            font-family:"Rubik-Medium";
            margin-right: 10px;
        }
        .remove-role-btn{
            width: 150px;
            height: 40px;
            background: rgba(156, 156, 156, 0.6);
            color:white;
            border:none;
            border-radius: 3px;
            font-size:110%;
            font-family:"Rubik-MEdium";
        }
    }

    > div: second-child {
        width: 100%;
        max-width: 1246px;
        height: 190px;
        display:flex;
      }

`;


const StyledTabContainer = styled(Tabs)`
   float: left;
   margin-bottom:25px;
  > ul {
    height: ${({ newPadding }) => newPadding ? '70px' : 'none'};
    border-bottom: none;
    padding: ${({ newPadding }) => newPadding ? '16px 10px 12px 18px' : '20px 20px 1px 33px'};
    font-size: 12px;
    text-transform: capitalize;
    color: rgb(153,153,153);
    background-color: #fff;
    
    
    > li {
      width:131px;
      height:40px;
      > a {
        font-size: 12px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-family: "Rubik-Medium";
        margin-right: 5vw;
        text-transform: capitalize;
        padding: 5px 5px 17px 5px;
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
    
    .active {
      border-bottom: none;
      > a {
        background-color: transparent;
        color: rgb(21,159,202);
        border: none;
        border-bottom: 3px solid rgb(21,159,202);
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid rgb(21,159,202);
          color: rgb(21,159,202);
        }
      }
    }
  }
`;


const StyledTabsNew = styled(StyledTabContainer)`
  width: 100%;
  float: left;
  margin: 0px 0px 10px;
  border-radius: 6px;
  padding: 10px 15px;
  border-bottom: none;
  padding: 10px 15px;
  font-size: 12px;
  text-transform: capitalize;
  color: rgb(153, 153, 153);
  background-color: rgb(255, 255, 255);

  .nav-item{
    padding: 0px;
    font-family: Rubik-regular;
    font-size: 18px;
    line-height: 21px;
    color: rgb(156, 156, 156) !important;
    margin-right: 51px;
  }

  .nav-item > button {
    color: rgb(156, 156, 156);
    width: 139px;
    margin-left: 15px;
    &:hover{
      border: unset !important; // none;
    }
  }

  button {
    border: unset !important;
  } 

  .nav-item > .active {
    background: rgba(0, 47, 71, 0.1) !important;
    border-radius: 3px !important;
    font-family: Rubik-medium !important;
    color: rgb(13, 66, 112) !important;
  }

  .tab-content {
    position: relative;
    margin-top: 70px;
  }

  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;

    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: -27px 41px 0 33px;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
      display: inline-block;
      float: right;
      margin: 0 41px 0 33px;
      padding-top: 10px;
    }
  }

  > ul {
    margin: 0;
    border-radius: 6px;
    padding: 10px 15px 10px 15px;
    margin-bottom:10px;
    @media (max-width: 600px) {
      min-height: 100px;
      margin-top: 30px;
      padding: 4px 0px;
    }
    > li {
      width: 18.5%;
      display: flex;
      justify-content: center;
      height: 30px;
      margin-right: 51px;
      width: 139px;
      height: 40px;
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        font-size: 15px;
        color: #CCCBDO;
        text-transform: none;
        font-family: "Rubik-Medium";
        margin-right: 0px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
          padding-left: 0;
          padding-right: 0;
          margin: 0;
          padding: 10px 0px;

          font-family: "Rubik-Regular";
          font-size: 18px;
          line-height: 21px;
          color: #9c9c9c;
        }
      }
    }

    .active {
      > a {
        &:hover,
        &:active,
        &:focus {
          color: rgb(51, 51, 51);
        }
      }
    }
  }

  li.first-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: "Rubik-Medium";
      font-size: 18px;
      line-height: 21px;
      color: #0d4270;
    }
  }
  li.second-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: "Rubik-Medium";
      font-size: 18px;
      line-height: 21px;
      color: #0d4270;
    }
  }
  li.third-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: "Rubik-Medium";
      font-size: 18px;
      line-height: 21px;
      color: #0d4270;
    }
  }
  li.first-tab.active {
    background: rgba(0, 47, 71, 0.1);
    border-radius: 3px;
  }
  li.second-tab.active {
    background: rgba(0, 47, 71, 0.1);
    border-radius: 3px;
  }
  li.third-tab.active {
    background: rgba(0, 47, 71, 0.1);
    border-radius: 3px;
  }
  > ul > li > a {
    padding: 0px;

    font-family: "Rubik-Regular";
    font-size: 18px;
    line-height: 21px;
    color: #9c9c9c;
  }
`;


const OverviewCard = styled.div`
    max-width: 1246px;
    ${'' /* height: 325px; */}
    background-color:#FFFFFF;
    border-radius: 6px;
    margin-bottom: 25px;
.viewcard{
    display:flex;  
    justify-content:space-between;
    ${'' /* margin-top: 70px; */}
  }
   .prof-details{
      width:100%;
      display:flex;
      justify-content:space-between;
      padding: 0px 25px 0px 25px;
      height:74px;
   }
   .name-in{
    display: flex;
    font-family:"Rubik-Medium";
      font-style: normal;
      align-items: center;
      color: #0D4270;
      font-size: 18px;
      line-height: 24px;
   }
    .horizontal-line{
        background: rgba(156, 156, 156, 0.4);
        margin-bottom: 25px;
        margin-top: 0px;
        height: 1px
    }
    .user-edit-btn{
      background: #FD7175;  
      width: 150px;
      height: 40px;
      border-radius: 3px;
      color: white;
      border:none;
      font-size:110%;
      font-family: "Rubik-Medium";
      align-items: center;
      display: flex;
      margin: auto;
      margin-right: 0px;
      text-align: center;
      justify-content: center;
   }
   .emp-detail{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display:flex;
    flex-wrap:wrap;
    display: flex;
    flex-direction: row;
    padding: 0px 25px;
    
    .fieldNames{
        width:50%;
        display: flex;
        height: 40px;
        .color{
          color:#FD7175;
          font-family: 'Rubik-Medium';
          font-size: 16px;
          line-height: 20px;
        }
        .green{
          color: #9FC989;
          font-family: 'Rubik-Medium';
          font-size: 16px;
          line-height: 20px;
        }
        .prof-data{
          color:#0D4270;
          font-family: 'Rubik-Medium';
          font-size: 16px;
          line-height: 20px;
        }  
    }
   }
   .label-name-emp{
    width:40%;
    color: #9C9C9C;
   } 
`;

const InitativeCard = styled.div`
.company-initative{
   width:100%;
   display: flex;
   background: #FFFFFF;
   flex-direction: column;
   margin-bottom:25px;
   border-radius: 6px;
}
.heading{
   color:#0D4270;
   font-family: 'Rubik-Medium';
   font-size: 18px;
   line-height:24px;
   margin-top: 25px;
   margin-left: 25px;
   display: flex;
   justify-content: ${({justify}) => justify ? '' : 'space-between'}
   align-items: center;
   .total{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color: #FD7175
    margin-left: 20px;
   }
   .button-container{
   .detail-button{
    min-width: 150px;
    height: 40px;
    background: #FD7175;
    border-radius: 3px;
    margin-right: 25px;
    border: none;
    color: #ffffff;
   }
  }
}
.horizontal-line2{
  margin-top: 25px;
  margin-bottom: 25px;
  background: rgba(156, 156, 156, 0.4);
  width: 100%;
  height:1px;
  border: 0px;
}
.fitness-wrapper{
   display:flex;
   padding: 0px 25px 10px 25px;
   width:100%;
   flex-wrap:wrap;
   >div{
    .syncing-title{
      width: auto;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display:flex;
      margin: auto;
      margin-left: 15px;
      margin-bottom: 45px;
      margin-top: 15px;
      flex-direction:column;
      color: #0D4270;
      margin: auto;
      margin-left: 15px;
    }
    .points{
      height: 20px;
      left: 547px;
      top: 1337px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      margin-right: -73px;
      margin-top: 11px;
    }
   }
}
.syncing-fitness{
  width: calc((100% - 50px) / 3);
  height: 80px;
  border: 1px solid #9C9C9C;
  border-radius: 7px;
  display:flex;
  cursor: pointer;
  margin-bottom: 25px;
  .syncing-title{
    width: auto;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 15px;
    margin-bottom: 45px;
    margin-top: 15px;
    flex-direction:column;
   
  }
  .syncing-title2{
    width: 187px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 15px;
    margin-bottom: 45px;
    margin-top: 15px;
    flex-direction:column;
  }
  .points{
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-right: -73px;
    margin-top: 11px;
  }
  .right-mark{
    width: 24px;
    height: 24px;
    margin-top: 28px;
    margin-bottom: 28px;
    margin-right: 10px;
  }
  .points2{
    width: 205px;
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-right: -73px;
    margin-top: 11px;
  }
  .points3{
    width: 68px;
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-right: -73px;
    margin-top: 11px;
  }
}
.rectangle-org{
  width: 60px
  height:60px
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
  >img{
    width:100%;
    height:100%
  }

}
.square-org{
  width: 60px
  height:60px
  background: linear-gradient(180deg, #FD7175 0%, #FF4C51 100%);
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
}
.cylinder-org{
  width: 60px
  height:60px
  background: linear-gradient(180deg, #C0ED98 0%, #96C26E 100%);
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
}
`;

const RecognitionCard = styled.div`

.recognize-received{
  width:100%;
  display: flex;
  background: #FFFFFF;
  flex-direction: column;
  margin-bottom: 25px;
  border-radius: 6px;
}
.heading2{
  color:#0D4270;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height:24px;
  margin-top: 25px;
  margin-left: 25px;
  display: flex;
  .count{
    font-family: 'Rubik-Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;    
    color: #FD7175;
    margin-left: 10px;
  }
}
.horizontal-line2{
  margin-top: 25px;
  margin-bottom: 25px;
  background: rgba(156, 156, 156, 0.4);
  height: 1px;
  width: 100%;
  border: 0px;
}
.welcome-wrapper{
  display:flex;
  padding: 0px 25px 10px;
  flex-wrap:wrap;

>div{
  .welldone-title{
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 20px;
    flex-direction:column;
    color:#0D4270;
  }
  .point{
    display:flex;
    margin: auto;
    margin-right: 15px;
    font-family: "Rubik-Medium";
    font-size: 18px;
    line-height: 24px;
    color:#0D4270;
  }
}
}
.well-done{
  width: calc((100% - 75px) / 4);
  height: 60px;
  border: 1px solid #9C9C9C;
  border-radius: 7px;
  display: flex;
  cursor: pointer;
  margin-bottom: 25px;
  .img{
    width:30px;
    height:30px;
    margin: auto;
    margin-right: 0px;
    margin-left: 15px;
    >img{
      width:100%;
      height:100%
    }
  }
}
`;


const DevicesCard = styled.div`
.wrapperHeading{
  display:flex;
  justify-content:flex-end;
  padding: 15px 0;
  .headingNew{
    color:#0D4270;
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height:24px;
    margin-top: 0px;
    margin-left: 25px;
    align-items: center;
    display: flex;
  }
  .refreshbutton{
    margin:auto;
    margin-right:25px;
  }
}
.connect-devices{
  width:100%;
  display: flex;
  background: #FFFFFF;
  flex-direction: column;
  border-radius: 6px;
}
.heading3{
  color:#0D4270;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height:24px;
  margin-top: 25px;
  margin-left: 25px;
}
.headingNew{
  color:#0D4270;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height:24px;
  margin-top: 0px;
  margin-left: 25px;
}
.horizontal-line2{
  margin-top: 25px;
  margin-bottom: 25px;
  background: rgba(156,156,156,0.4);
  height: 1px;
  width: 100%;
  border: 0px;
}
.horizontal-line{
  margin-top: 0px;
  margin-bottom: 25px;
  background: rgba(156,156,156,0.4);
  height: 1px;
  width: 100%;
  border: 0px;
}
.device-wrapper{
  display:flex;
  padding: 0px 25px 0px 25px;
  width:100%;
  flex-wrap: wrap;
  .img2{
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    >img{
      height: 60px;
    }}
    .wrapper{
      display: block;
      margin: auto;
      margin-left: 15px;
    .watch-title{
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display:flex;
      flex-direction:column; 
    }
    .bits{
      font-family: Rubik;
      font-size: 16px;
      line-height: 20px;
      color: #9FC989;
      margin-top: 10px;
    }
  }
}
.watch{
  width:calc((100% - 50px) / 3);
  height: 80px;
  border: 1px solid #9C9C9C;
  border-radius: 7px;
  display:flex;
  cursor: pointer;
  .img2{
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    >img{
      height: 60px;
    }
    .whiteout{
      width:60px
      height:60px
      margin-top:10px;
      margin-bottom:10px
      margin-left:10px;
    }
    .watchtag{
      width:60px;
      height:13.7px
      margin-top:23px;
      margin-bottom:23.3px;
    }
  }
  .watch-title{
    width: 93px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 15px;
    margin-right: 204px;
    flex-direction:column; 
  }
  .bits{
    width: 212px;
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #9FC989;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
`;

const WellnessCard = styled.div`
  .wellness-interest{
    margin: 0px 0px 25px;
    max-width: 1246px;
    background-color:#FFFFFF;
    border-radius: 6px;
  }
  .wellness-title{
    padding: 25px;
  }
  .title-text{
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #0D4270;    
    margin-right: 25px;
  }
  .wellness-count{
    font-family: 'Rubik-Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;    
    color: #FD7175
  }
  .interest-card-wrapper{
    padding:25px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .horizontal-line{
    background: rgba(156,156,156,0.4);
    height: 1px;
    margin-top: unset;
    margin-bottom: unset;
    border: 0px;
  } 
  .interest-card{
    width: calc((100% - 75px) / 4);
    height: 60px;
    border: 1px solid #9C9C9C;
    border-radius: 7px;
    margin-bottom: 20px;
  }

  .icon-wrapper{
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .interest-card{
  display:flex;
  }
  .interest-icon{
    height: 40px;
    width: 40px;
    display: flex;
    background: #0D4270;
    align-items: center;
    border-radius: 6px;
    >img{
      width: 25px;
      height: 25px;
      margin: auto;
    }
  }
  .interest-name{
    display: flex;
    align-items: center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0D4270;
  }
`;

const AboutCard = styled.div`
  .about-user-section{
    width: 100%;
    background: #FFFFFF;
    border-radius: 6px;
    margin: 0px 0px 25px;
  }
  .title-text{
    padding: 25px 25px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
  .about-user-details{
    padding: 25px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
  }
  .horizontal-line{
    height: 1px;
    background: rgba(156, 156, 156, 0.4);
    color: #9C9C9C;
    margin-top: unset;
    margin-bottom: unset;
    border: 0px;
  } 
  
`;

const RecognitionCardV2 = styled.div`

width: calc((100% - 90px) / 4);
height: 60px;
border: 1px solid #9C9C9C;
border-radius: 7px;
display: flex;
// cursor: pointer;
margin-bottom: 15px;
margin-right: ${({marginRight}) => marginRight ? marginRight : '25px'};
:nth-child(4n) {
  margin-right: 0; /* Remove margin-right for every 4th card */
  }
.img{
  width:30px;
  height:30px;
  margin: auto;
  margin-right: 0px;
  margin-left: 15px;
  >img{
    width:100%;
    height:100%
  }

}

.recognize-received{
  width:100%;
  display: flex;
  background: #FFFFFF;
  flex-direction: column;
  margin-bottom: 25px;
}
.heading2{
  color:#0D4270;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height:24px;
  margin-top: 25px;
  margin-left: 25px;
}
.horizontal-line2{
  margin-top: 25px;
  margin-bottom: 25px;
  background: rgba(156, 156, 156, 0.4);
  height: 1px;
  border: 0px;
  width: 100%;
}
.welcome-wrapper{
  display:flex;
  justify-content: space-between;
  padding: 0px 25px 10px;
  flex-wrap:wrap;
  >div{
    margin-bottom:15px;
  }
}

.test{
  margin-bottom:15px !important;
}
.well-done{
  width: calc((100% - 75px) / 4);
  height: 60px;
  border: 1px solid #9C9C9C;
  border-radius: 7px;
  display: flex;
  cursor: pointer;
  margin-bottom: 25px;
  .img{
    width:40px;
    height:38.63px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 11.37px;
    >img{
      width:100%;
      height:100%
    }
  }
  .welldone-title{
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    flex-direction:column;
    color:#0D4270;
    display: flex;
    margin: auto;
    margin-left: 15px;
  }
  .point{
    margin-top: 21px;
    margin-right: 15px;
    margin-bottom: 18px;
    display: flex;
    margin: auto;
    margin-right: 15px;
  }
}
`;

const InitativeCardV2 = styled.div`
width: calc((100% - 50px) / 3);
height: 80px;
border: 1px solid #9C9C9C;
border-radius: 7px;
display:flex;
// cursor: pointer;
margin-bottom: 15px;
margin-right: ${({marginRight}) => marginRight ? marginRight : '25px'};
.syncing-title{
  .points{
    color: ${({color}) => color ? color : '#0D4270'};
  }
}
.company-initative{
   width:100%;
   display: flex;
   background: #FFFFFF;
   flex-direction: column;
   margin-bottom:25px;
}
.heading{
   color:#0D4270;
   font-family: 'Rubik-Medium';
   font-size: 18px;
   line-height:24px;
   margin-top: 25px;
   margin-left: 25px;
}
.horizontal-line2{
  margin-top: 25px;
  margin-bottom: 25px;
  background: rgba(156, 156, 156, 0.4);
  height: 1px;
  border: 0px;
  width: 100%;
}
.fitness-wrapper{
   display:flex;
   justify-content: space-between;
   padding: 0px 25px 0px 25px;
   width:100%;
   flex-wrap:wrap;
}
.syncing-fitness{
  width: calc((100% - 50px) / 3);
  height: 80px;
  border: 1px solid #9C9C9C;
  border-radius: 7px;
  display:flex;
  cursor: pointer;
  margin-bottom: 25px;
  .syncing-title{
    width: auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 15px;
    margin-bottom: 45px;
    margin-top: 15px;
    flex-direction: column;
    margin: auto;
    margin-left: 15px;
  }
  .syncing-title2{
    width: 187px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 15px;
    margin-bottom: 45px;
    margin-top: 15px;
    flex-direction:column;
  }
  .points{
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-right: -73px;
    margin-top: 11px;
  }
  .points2{
    width: 205px;
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-right: -73px;
    margin-top: 11px;
  }
  .points3{
    width: 68px;
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F8A887;
    margin-right: -73px;
    margin-top: 11px;
  }
}
.rectangle-org{
  width: 60px;
  height:60px;
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
  >img{
    width:100%;
    height:100%
  }
}
.right-mark{
  margin-top: 28px;
  margin-bottom: 28px;
  margin-right: 10px;
}
.square-org{
  width: 60px;
  height:60px;
  background: linear-gradient(180deg, #FD7175 0%, #FF4C51 100%);
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
}
.cylinder-org{
  width: 60px;
  height:60px;
  background: linear-gradient(180deg, #C0ED98 0%, #96C26E 100%);
  border-radius: 4.2px;
  display:flex;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
}
`;

const WellnessCardV2 = styled.div`
width: calc((100% - 75px) / 4);
height: 60px;
border: 1px solid #9C9C9C;
border-radius: 7px;
margin-bottom: 15px;
display: flex;
margin-right: ${({marginRight}) => marginRight ? marginRight : '25px'};
  .wellness-interest{
    margin: 10px 0px;
    max-width: 1246px;
    background-color:#FFFFFF;
    border-radius: 6px;
  }
  .wellness-title{
    padding: 25px;
  }
  .title-text{
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #0D4270;    
    margin-right: 25px;
  }
  .wellness-count{
    font-family: 'Rubik-Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;    
    color: #FD7175
  }
  .interest-card-wrapper{
    padding:25px;
    padding-bottom: 7px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .horizontal-line{
    border: 1px solid rgba(156, 156, 156, 0.4);
    margin-top: unset;
    margin-bottom: unset;
  } 
  .interest-card{
    width: 23.5%;
    height: 60px;
    border: 1px solid #9C9C9C;
    border-radius: 7px;
    margin-bottom: 20px;
  }

  .icon-wrapper{
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .interest-card{
  display:flex;
  }
  .interest-icon{
    height: 40px;
    width: 40px;
    display: flex;
    background: #0D4270;
    align-items: center;
    border-radius: 6px;
    >img{
      width: 25px;
      height: 25px;
      margin: auto;
    }
  }
  .interest-name{
    display: flex;
    align-items: center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0D4270;
  }
`;

const DevicesCardV2 = styled.div`
width:calc((100% - 50px) / 3);
height: 80px;
border: 1px solid #9C9C9C;
border-radius: 7px;
display:flex;
cursor: pointer;
margin-right: ${({marginRight}) => marginRight ? marginRight : '25px'};
margin-bottom: 25px;
.connect-devices{
  width:100%;
  display: flex;
  background: #FFFFFF;
  flex-direction: column;
}
.heading3{
  color:#0D4270;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height:24px;
  margin-top: 25px;
  margin-left: 25px;
}
.horizontal-line2{
  background: rgba(156, 156, 156, 0.4);
  height: 1px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 0px;
  
}
.device-wrapper{
  display:flex;
  justify-content: space-between;
  padding: 0px 25px 0px 25px;
  width:100%;
  flex-wrap: wrap;
}
.watch{
  width:calc((100% - 50px) / 3);
  height: 80px;
  border: 1px solid #9C9C9C;
  border-radius: 7px;
  display:flex;
  cursor: pointer;
  .img2{
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    >img{
      height: 60px;
    }
    .whiteout{
      width:60px;
      height:60px;
      margin-top:10px;
      margin-bottom:10px;
      margin-left:10px;
    }
    .watchtag{
      width:60px;
      height:13.7px;
      margin-top:23px;
      margin-bottom:23.3px;
    }
  }
  .watch-title{
    width: 93px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display:flex;
    margin: auto;
    margin-left: 15px;
    margin-right: 204px;
    flex-direction:column; 
  }
  .bits{
    width: 212px;
    height: 20px;
    left: 547px;
    top: 1337px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #9FC989;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
`;

const StyledTabsNewV2 = styled(StyledTabContainer)`
  width: 100%;
  margin: 0px 0px 10px;
  border-radius: 6px;
  padding: 15px 15px 15px 0px;
  background: none;
  border-bottom: none;
  font-size: 16px;
  text-transform: capitalize;
  color: rgb(153, 153, 153) !important;

  .nav-item {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    height: 40px;
    // border: 1px solid rgb(156, 156, 156);
    // border-radius: 3px;
    margin-right: 15px;
    color: rgb(153, 153, 153);
  }

  .nav-item > .active{
    background: rgb(105, 194, 255) !important;
    border-radius: 3px !important;
    border: unset !important; // none;
    color: rgb(255, 255, 255) !important;
  }

  .nav-tabs .nav-link {
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
  }

  .nav-tabs{
    border: 0px !important;
    background: white !important;
    border-radius: 2px !important;
  }

  button {
    color: rgb(156, 156, 156) !important;
    border: 1px solid rgb(156, 156, 156) !important;
    border-radius: 3px !important;
    font-family: Rubik-Medium;
  }
  
  .tab-content {
    position: relative;
  }
  
  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;
    
    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
      
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: -27px 41px 0 33px;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
        display: inline-block;
        float: right;
        margin: 0 41px 0 33px;
        padding-top: 10px;
    }
  }
  
  > ul {
    margin: 0;
    border-radius: 6px;
    padding: 15px 15px 15px 0px;
    margin-bottom:10px;
    background: white;
    border-radius: 0px;
    @media (max-width: 600px) {
      min-height: 100px;
      margin-top: 30px;
      padding: 4px 0px;
      
    }
    > li {
      width: 18.5%;
      display: flex;
      justify-content: center;
      height: 40px;
      border: 1px solid #9C9C9C;
      border-radius: 3px;
      margin-right: 15px;
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        text-transform: none;
        font-family: "Rubik";
        margin-right: 0px;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        padding: 10px 0px;
        font-size: 16px;
        line-height: 20px;
       }
      }
    }

    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }

  li.first-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#FFFFFF;;
    }
  }
  li.second-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#FFFFFF;
    }
  }
  li.third-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#FFFFFF;;
    }
  }
  li.first-tab.active {
    background: #69C2FF;
    border-radius: 3px;
    border:none;
    width: 9%;
    max-width:100px;
  }
  li.first-tab{
    width: 9%;
    max-width:100px;
  }
  li.second-tab.active {
    background: #69C2FF;
    border-radius: 3px;
    border: none;
    width: 14%;
    max-width: 160px;
  }
  li.second-tab {
    width: 14%;
    max-width: 160px;
  }
  li.third-tab.active {
    background: #69C2FF;
    border-radius: 3px;
    border: none;
  }
  > ul > li > a{
    padding: 0px;

    font-family: 'Rubik-Regular';
    font-size: 16px;
    line-height: 20px;
    color:#9C9C9C;
  }
`;

const ModalBodyContainer = styled.div`
  width:100%;
  display:block;
  .top-section{
    width:100%;
    display:block;
    padding:25px 25px 10px 25px;
  .title{
    font-family: Rubik-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color:#0D4270;
    padding-bottom:10px;
  }
  .subTitle{
    font-family: Rubik-Medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color:#9c9c9c;
    padding-bottom:25px;
  }
  .checkBoxWidth{
    width:100%;
    display:flex;
    align-items:center;
    margin-bottom:15px;
    span{  width:auto;
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0D4270;
      display:flex;
      justify-content:flex-start;
      align-items:center;
      flex-direction:row;
    }
  }
}
  .buttonWrapper{
    display:flex;
    justify-content:center;
    border-top:1px solid #9c9c9c;
    padding:25px;
    width:100%;
  }
`;

const Button = styled.div`
  width:100%;
  height:50px;
  display:flex;
  justify-content: center;
  align-items: center;
  margin:auto;
  border-radius:6px;
  font-family: Rubik-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color:white;
  cursor:${({disable}) => disable ? 'not-allowed' : 'pointer'};
  background: ${({disable}) => disable ? 'rgb(156, 156, 156, .6)' : '#FD7175'};
`;

export { PersonDetailCard, StyledTabsNew, StyledTabContainer, OverviewCard, InitativeCard, RecognitionCard, DevicesCard, WellnessCard, AboutCard, RecognitionCardV2, InitativeCardV2,WellnessCardV2, DevicesCardV2 ,StyledTabsNewV2, ModalBodyContainer, Button };